



































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'

export default Vue.extend({
  data() {
    return {
      title: '',
      tabActive: 0,
      tabList: [],
      tabId: '',

      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      tab: 0,
    }
  },
  watch: {
    title() {
      this.onInit()
    },
  },
  async created() {
    await this.getBarList()
    this.onInit()
  },
  methods: {
    changeTab(tab) {
      this.tabId = this.tabList[this.tabActive].id
      this.onInit()
    },
    getBarList() {
      return request.get('/api/column/rest_category/list').then((data) => {
        this.tabList = data
        this.tabId = this.tabList[this.tabActive].id
      })
    },
    onInit() {
      this.pageNum = 1
      this.getData()
    },
    onLoad() {
      this.pageNum += 1
      this.getData()
    },
    onClick(name) {
      if (this.tab === 0) {
        sessionStorage.setItem(`tabActive1`, name)
      } else if (this.tab === 1) {
        sessionStorage.setItem(`tabActive2`, name)
      }
      this.tabId = this.tabList[name].id
      this.pageNum = 1
      this.getData()
    },
    getData() {
      this.loading = true
      const { pageNum, pageSize, title, tabId } = this
      const params = { pageNum, pageSize, title, categoryId: tabId } as any
      // console.log(params)
      request.get('api/column/article/page', params).then(({ list, total }) => {
        list.map((e) => {
          e.imgUrl = ''
          e.imageUrl = ''
          if (e.categoryId === '409dae664c9849839d37b2b75bd570b7') {
            e.imageUrl = '/static/images/new/tec1.png'
          } else if (e.categoryId === '15f4ef2033ca41adbdc8755a4a92c514') {
            e.imageUrl = '/static/images/tec.png'
          } else if (e.categoryId === 'bcd5230c936f4741be3bf9ad83c0ba6b') {
            e.imageUrl = '/static/images/new/tec3.png'
          } else if (e.categoryId === 'e78818e24c584a488ba291ebf5bdac91') {
            e.imageUrl = '/static/images/new/tec2.png'
          } else if (e.image === 'default.png') {
            e.imgUrl = '/static/images/tec.png'
          } else if (e.image) {
            e.imgUrl = 'https://ylwx.sxktqx.com/columnfiles' + e.image
          }
        })
        if (pageNum == 1) this.list = list
        else this.list = this.list.concat(list)
        this.total = total
        this.loading = false
      })
    },
    view(id: string) {
      this.$router.push(`/classroom_view/${id}`)
      // // doc
      // this.$router.push({
      //     path: '/cxo_view',
      //     query: {url: `/files${url}`},
      // })
      // // editor
      // this.$router.push(`/classroom_view/${id}`)
      // // mp4
      // let baseUrl = ''
      // if (process.env.NODE_ENV=='development') {
      //     baseUrl = 'http://ylwx.sxktqx.com'
      // }
      // window.location.href = `${baseUrl}/files${url}`
      // // pdf
      // this.$router.push({
      //     path: '/file_view',
      //     query: {url: `/files${url}`},
      // })
    },
  },
  filters: {
    dtFormat(timestamp: number) {
      const time = moment(timestamp)
      return time.format('YYYY-MM-DD')
    }
  }
})
